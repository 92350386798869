import './App.css';
import {useEffect, useState} from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from "./components/LoginPage";
import Layout from "./components/layout/Layout";
import ProtectedRoute from "./components/ProtectedRoute";
import CreatorApplyList from "./components/creator/CreatorApplyList";
import BusinessApplyList from "./components/business/BusinessApplyList";
import CreatorApplyDetail from "./components/creator/CreatorApplyDetail";
import BusinessApplyDetail from "./components/business/BusinessApplyDetail";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // 초기값을 null로 설정

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  if (isAuthenticated === null) {
    // 인증 여부가 확인되기 전에는 로딩 화면을 표시
    return <div>로딩 중...</div>;
  }

  return (
      <Router>
        <Routes>
          <Route
              path="/"
              element={
                isAuthenticated ? <Navigate to="/creator-apply" /> : <Navigate to="/login" />
              }
          />
          <Route
              path="/login"
              element={<LoginPage setIsAuthenticated={setIsAuthenticated} />}
          />
          <Route
              path="/"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Layout />
                </ProtectedRoute>
              }
          >
            <Route path="/creator-apply" element={<CreatorApplyList />} />
            <Route path="/business-apply" element={<BusinessApplyList />} />
            <Route path="/creator-apply/:id" element={<CreatorApplyDetail />} />
            <Route path="/business-apply/:id" element={<BusinessApplyDetail />} />
            {/*<Route path="/logList" element={<LogList />} />*/}
            {/*<Route path="/companyList" element={<CompanyList />} />*/}
            {/*<Route path="/userList" element={<UserList />} />*/}
            {/*<Route path="/settingList" element={<SettingList />} />*/}
          </Route>
        </Routes>
      </Router>
  );
}

export default App;

