import {CustomError} from './CustomError';

export const handleApiResponse = async (apiCall) => {
    try {
        const response = await apiCall();
        if (response.data.status === 200) {
            return { success: true, data: response.data.data, status: response.data.status };
        } else {
            console.log(response.data?.error);
            return CustomError(
                false,
                response.data.error || "API Error",
                response.data.status,
                response.data.result
            );
        }
    } catch (error) {
        if (!error.response) {
            console.error("서버에 연결할 수 없습니다:", error.message);
            throw new CustomError(
                false,
                "서버에 연결할 수 없습니다. 잠시 후 다시 시도해주세요.",
                0,
                null
            );
        }

        if (error.response?.data?.error) {
            console.log(error.response.data?.error);
            const message = error.response.data.error;
            const status = error.response.status || 500;
            const result = error.response.data.result || null;
            throw new CustomError(false, message, status, result);
        }
        throw new Error(error.message || "알 수 없는 오류가 발생했습니다.");
    }
};
