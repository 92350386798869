export const formatDateTime = (dateString, formatType = 'yyyy.MM.dd HH:mm') => {
    if(dateString == null) {
        return null
    }
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    switch (formatType) {
        case 'yyyy.MM.dd':
            return `${year}.${month}.${day}`;
        case 'yyyy.MM.dd HH:mm':
            return `${year}.${month}.${day} ${hours}:${minutes}`;
        case 'yyyy.MM.dd HH:mm:ss':
            return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
        default:
            return `${year}.${month}.${day} ${hours}:${minutes}`; // Default to 'yyyy.MM.dd HH:mm'
    }
};
