import styled from "styled-components";

// 헤더 스타일
export const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 2rem;
    height: 70px;
    background-color: #000;
    position: fixed; /* 헤더를 고정시켜서 사이드바에 가려지지 않도록 함 */
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid #404348;
`;

// 로고 스타일
export const Logo = styled.div`
    display: flex;
    align-items: center;

    img {
        height: 26px;
        width: 252px;
    }
`;

// 전체 컨테이너 스타일
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #040610;
    padding-top: 70px; /* 헤더의 높이만큼 여백을 줘서 콘텐츠가 그 아래에 시작하도록 함 */
`;

// 메인 영역 스타일 (사이드바와 콘텐츠 영역을 포함)
export const Main = styled.div`
    display: flex;
    flex: 1; /* 콘텐츠가 남은 공간을 차지하도록 설정 */
`;

// 콘텐츠 영역 스타일
export const Content = styled.div`
    flex: 1;
    padding: 20px;
    height: 100%;
    background-color: #040610;
    overflow-y: auto;
`;

// 사이드바 스타일
export const SidebarContainer = styled.div`
    width: 300px;
    background-color: #0F111B;
    color: white;
    height: 100%;
    font-family: "Noto Sans KR";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

// 사이드바 메뉴 리스트 스타일
export const SidebarMenu = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;


// 사이드바 메뉴 아이템 스타일
export const SidebarMenuItem = styled.li`
    display: block;
    margin: 5px 0;
    overflow: hidden;

    a {
        color: white;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: left;
        text-align: center;
        padding: 15px 30px;
        border-radius: 4px;
        transition: background 0.3s ease;

        &.active {
            background: rgba(255, 255, 255, 0.04); /* 선택된 배경색 */
            font-weight: bold; /* 텍스트 굵게 */
        }
    }
`;

// 사이드바 푸터 스타일
export const SidebarFooter = styled.div`
    margin-top: auto;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    color: #aaa;
`;
