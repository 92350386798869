import {handleApiResponse} from "../utils/ApiUtils";
import api from "./api";

export const fetchCreatorApplyList = async (lastId, searchQuery, limitCount) => {
    return handleApiResponse(() => api.get(`/creator-apply`, {
        params: {lastId, nickname: searchQuery, limitCount}
    }));
};

export const fetchCreatorApplyDetail = async (id) => {
    return handleApiResponse(() => api.get(`/creator-apply/${id}`));
};

export const updateStatus = async (id, status, reason) => {
    return handleApiResponse(() => api.post(`/creator-apply/${id}/status`, {
        status,
        rejectReason: reason
    }));
};