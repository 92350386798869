import {
    H2,
    SearchBox,
    SearchIcon,
    SearchInput,
    SearchInputWrapper,
    Table, TableCell,
    TableHeader, TableRow
} from "./BusinessApplyList.style";
import {formatDateTime} from "../../utils/DateUtil";
import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {fetchBusinessApplyList} from "../../service/BusinessApplyService";

const BusinessApplyList = () => {

    const [applyList, setApplyList] = useState([]);
    const [lastId, setLastId] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const observerRef = useRef(null);
    const LIMIT = 20;

    const navigate = useNavigate();

    const fetchData = async (isInitialLoad = false, searchFlag = false) => {
        if (loading || (!hasMore && !isInitialLoad)) return; // 중복 호출 방지
        setLoading(true);
        try {
            const result = await fetchBusinessApplyList(
                searchFlag ? null : lastId,
                searchQuery,
                LIMIT
            );

            const items = result.data.items;

            setApplyList((prev) => (isInitialLoad ? items : [...prev, ...items]));

            if (items.length < LIMIT) {
                setHasMore(false);
            } else {
                setLastId(result.data.lastId);
                setHasMore(true);
            }
        } catch (error) {
            console.error("데이터 가져오기 실패:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(true, true);
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && hasMore && !loading) {
                    fetchData(false);
                }
            },
            { threshold: 1.0 }
        );

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current);
            }
        };
    }, [hasMore, loading]);

    const handleSearchClick = async () => {
        if (loading) return;
        const newLastId = null;
        setLastId(newLastId);
        setApplyList([]);
        setHasMore(true);
        await fetchData(true, true);
    };

    return (
        <div>
            <SearchBox>
                <H2>크리에이터 신청 관리</H2>
                <SearchInputWrapper>
                    <SearchInput
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="신청자 아이디"
                    />
                    <SearchIcon onClick={handleSearchClick} />
                </SearchInputWrapper>
            </SearchBox>
            <Table>
                <thead>
                <tr>
                    <TableHeader>업체명</TableHeader>
                    <TableHeader>신청자</TableHeader>
                    <TableHeader>신청일</TableHeader>
                    <TableHeader>내역</TableHeader>
                </tr>
                </thead>
                <tbody>
                {applyList.length === 0 && !loading ? (
                    <TableRow>
                        <TableCell colSpan={4} style={{ textAlign: "center", padding: "20px", color: "#aaa" }}>
                            결과가 없습니다.
                        </TableCell>
                    </TableRow>
                ) : (
                    applyList.map((item, index) => (
                        <TableRow key={index} onClick={() => navigate(`/business-apply/${item.id}`)}>
                            <TableCell>{item.businessName}</TableCell>
                            <TableCell>{item.nickname}</TableCell>
                            <TableCell>{formatDateTime(item.createdAt, "yyyy.MM.dd")}</TableCell>
                            <TableCell>
                                {item.state === 'SUBMITTED' && "신청"}
                                {item.state === 'REJECTED' && "거절"}
                                {item.state === 'REGISTERED' && "승인"}
                            </TableCell>
                        </TableRow>
                    ))
                )}
                {loading && (
                    <TableRow>
                        <TableCell colSpan={4} style={{ textAlign: "center", padding: "20px" }}>
                            로딩 중...
                        </TableCell>
                    </TableRow>
                )}
                </tbody>
            </Table>
            <div ref={observerRef} style={{ height: "1px" }} />
        </div>
    );
}

export default BusinessApplyList