import styled from "styled-components";
import logoImage from '../images/day-vent-logo.png';
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {getAuthCode, login} from "../service/api";

const LoginPage = ({ setIsAuthenticated }) => {
    const [phone, setPhone] = useState('');
    const [authCode, setAuthCode] = useState('');
    const [showAuthInput, setShowAuthInput] = useState(false);
    const [remainingTime, setRemainingTime] = useState(180); // 3분 남은 시간
    const [isSending, setIsSending] = useState(false); // 인증번호 요청 중인지 여부
    const navigate = useNavigate();

    // 인증번호 요청
    const handleSendAuthCode = async () => {
        if (!phone.trim()) {
            alert("휴대폰 번호를 입력해주세요.");
            return;
        }
        setIsSending(true);
        try {
            await getAuthCode(phone)
            setShowAuthInput(true);
            alert("인증번호가 발송되었습니다.");
            startTimer();
        } catch (err) {
            console.error('인증번호 요청 중 오류:', err);
        } finally {
            setIsSending(false);
        }
    };

    // 타이머 시작
    const startTimer = () => {
        setRemainingTime(180); // 3분 초기화
        const timer = setInterval(() => {
            setRemainingTime((prev) => {
                if (prev <= 1) {
                    clearInterval(timer);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    };

    const handleLogin = async () => {
        if (!authCode.trim()) {
            alert("인증코드를 입력해주세요.");
            return;
        }
        try {
            const result = await login(phone, authCode);
            console.log(result);
            if (result && result.data) {
                const token = result.data.accessToken;
                localStorage.setItem('token', token);
                setIsAuthenticated(true);
                navigate('/creator-apply');
            } else {
                console.error('로그인 실패: 토큰이 제공되지 않았습니다.');
            }
        } catch (err) {
            console.error('로그인 중 오류가 발생했습니다.');
        }
    };

    return (
        <Container>
            <Header>
                <Logo>
                    <img src={logoImage} alt="Dayvent Business" />
                </Logo>
            </Header>
            <Content>
                <LoginBox>
                    <HeaderText>데이벤트 어드민 로그인</HeaderText>
                    <InputWrapper>
                        <Input
                            id="phone"
                            type="text"
                            placeholder="휴대폰 번호를 입력해주세요."
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            disabled={showAuthInput}
                        />
                        <Button onClick={handleSendAuthCode} disabled={isSending}>
                            {isSending ? "재발송" : "인증번호 받기"}
                        </Button>
                    </InputWrapper>
                    {showAuthInput && (
                        <>
                            <InputWrapper>
                                <Input
                                    id="authCode"
                                    type="text"
                                    placeholder="인증번호 6자리를 입력해주세요."
                                    value={authCode}
                                    onChange={(e) => setAuthCode(e.target.value)}
                                />
                            </InputWrapper>
                            <TimerText>
                                남은 시간: {Math.floor(remainingTime / 60)}:{remainingTime % 60 < 10 ? `0${remainingTime % 60}` : remainingTime % 60}
                            </TimerText>
                            <ButtonWrapper>
                                <Button onClick={handleLogin} disabled={remainingTime === 0}>인증확인</Button>
                            </ButtonWrapper>
                        </>
                    )}
                </LoginBox>
            </Content>
        </Container>
    );
};

export default LoginPage;

// Styled Components
const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #040610;
`;

const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 2rem;
    height: 70px;
    background-color: #000;
`;

const Logo = styled.div`
    display: flex;
    align-items: center;

    img {
        height: 26px;
        width: 252px;
    }
`;

const Content = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #040610;
`;

const LoginBox = styled.div`
    width: 100%;
    max-width: 360px;
    padding: 2rem;
    text-align: center;
`;

const HeaderText = styled.h3`
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
`;

const InputWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 1rem;

    input {
        flex: 1;
        padding: 0.8rem;
        font-size: 1rem;
        border: 1px solid #333;
        border-radius: 6px;
        background-color: #111;
        color: #fff;

        &::placeholder {
            color: #555;
        }
    }
`;

const Input = styled.input`
    flex: 1;
`;

const TimerText = styled.div`
    margin-bottom: 1rem;
    font-size: 0.9rem;
    color: #ff5555;
    text-align: left;
`;

const ButtonWrapper = styled.div`
    text-align: center;
`;

const Button = styled.button`
    padding: 0.8rem 1.5rem;
    background-color: #0f0f0f;
    color: #fff;
    border: 1px solid #3f3f3f;
    border-radius: 999px;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;

    &:hover {
        background-color: #3f3f3f;
    }

    &:disabled {
        background-color: #333;
        color: #888;
        cursor: not-allowed;
    }
`;
