import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #1c1c1c;
    color: #fff;
    min-height: 100vh;
`;

export const LeftSection = styled.div`
    flex: 2;
    background-color: #2c2c2c;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const RightSection = styled.div`
    flex: 1;
    background-color: #2c2c2c;
    border-radius: 10px;
    padding: 20px;
    margin-left: 90px;
`;

export const SectionTitle = styled.h3`
    border-bottom: 1px solid #444;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-size: 1.2rem;
`;

export const InfoTable = styled.div`
    display: table;
    width: 100%;
    margin-bottom: 20px;
`;

export const InfoRow = styled.div`
    display: table-row;
    margin-bottom: 10px;
`;

export const InfoCellHead = styled.div`
    color: #888;
    font-family: "Noto Sans KR";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:first-child {
        font-weight: bold;
        width: 30%;
    }
`;

export const InfoCell = styled.div`
    display: table-cell;
    padding: 10px 0;
    color: white;

    &:first-child {
        font-weight: bold;
        width: 30%;
    }
`;

export const SelectBox = styled.select`
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
`;

export const RadioButton = styled.div`
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex-direction: row-reverse;
`;


export const Title = styled.h2`
    color: #EFEFEF;
    text-align: center;
    font-family: "Noto Sans KR";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
export const SubTitle = styled.h2`
    color: #888;
    text-align: center;
    font-family: "Noto Sans KR";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const Button = styled.button`
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;

    &:hover {
        background-color: #45a049;
    }
`;
