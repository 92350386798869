export class CustomError extends Error {
    constructor(success, message, status, result = null) {
        super(message);
        this.name = "CustomError";
        this.success = success;
        this.status = status;
        this.result = result;
    }

    toJSON() {
        return {
            success: this.success,
            status: this.status,
            error: this.message,
            result: this.result,
        };
    }
}