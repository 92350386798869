import axios from 'axios';

// const API_URL = 'http://localhost:5000/api-admin';
// const LOGIN_URL = 'http://localhost:5000/api/admin/auth/sign-in';
// const AUTH_CODE_URL = 'http://localhost:5000/api/user/auth/sms/code/send';

const API_URL = 'https://dev-server.day-vent.com/api-admin';
const LOGIN_URL = 'https://dev-server.day-vent.com/api/admin/auth/sign-in';
const AUTH_CODE_URL = 'https://dev-server.day-vent.com/api/user/auth/sms/code/send';

// 기본 API 설정
const api = axios.create({
    baseURL: API_URL,
});


// 요청 인터셉터: 모든 요청에 토큰 추가
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 응답 인터셉터: 401 또는 403 응답 처리
api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            if (error.response?.data?.error) {
                alert(error.response.data.error);
            } else {
                alert('로그인 시간이 만료되었습니다. 다시 로그인해 주세요.');
            }
            localStorage.removeItem('token');
            window.location.href = '/login';
        } else if (error.response && error.response.status === 403) {
            if (error.response?.data?.error) {
                alert(error.response.data.error);
            }
        }
        return Promise.reject(error);
    }
);

export const login = async (phone, authCode) => {
    try {

        const response = await axios.post(LOGIN_URL, {phone, code: authCode});
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getAuthCode = async (phone) => {
    try {
        const response = axios.post(AUTH_CODE_URL, {phone});
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default api;
