import {handleApiResponse} from "../utils/ApiUtils";
import api from "./api";

export const fetchBusinessApplyList = async (lastId, searchQuery, limitCount) => {
    return handleApiResponse(() => api.get(`/place`, {
        params: {lastId, nickname: searchQuery, limitCount}
    }));
};

export const fetchBusinessApplyDetail = async (businessId) => {
    return handleApiResponse(() => api.get(`/place/${businessId}`, {
        params: {businessId}
    }));
};


export const updateBusinessState = async (businessId, state, finalReason) => {
    return handleApiResponse(() => api.put(`/place/${businessId}`, {
        businessState: state, rejectedReason: finalReason
    }));
}