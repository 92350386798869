import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import logoImage from "../../images/day-vent-logo.png";
import {
    Header,
    Container,
    Logo,
    Main,
    Content,
    SidebarContainer,
    SidebarMenuItem,
    SidebarMenu,
} from './Layout.style';

const Layout = () => {
    return (
        <Container>
            <Header>
                <Logo>
                    <img src={logoImage} alt="Dayvent Business" />
                </Logo>
            </Header>
            <Main>
                <SidebarContainer>
                    <SidebarMenu>
                        <SidebarMenuItem>
                            <NavLink
                                to="/creator-apply"
                                className={({ isActive }) => (isActive ? 'active' : '')}
                            >
                                크리에이터 신청 관리
                            </NavLink>
                        </SidebarMenuItem>
                        <SidebarMenuItem>
                            <NavLink
                                to="/business-apply"
                                className={({ isActive }) => (isActive ? 'active' : '')}
                            >
                                비즈니스 신청 관리
                            </NavLink>
                        </SidebarMenuItem>
                    </SidebarMenu>
                </SidebarContainer>
                <Content>
                    <Outlet />
                </Content>
            </Main>
        </Container>
    );
};

export default Layout;
