import styled from 'styled-components';
import { FaSearch } from "react-icons/fa";

// Table styles
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #1f1f1f;
  color: #ffffff;
`;

export const TableHeader = styled.th`
    padding: 20px 0px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
`;

export const TableCell = styled.td`
    padding: 20px 0px;
    text-align: center;
    vertical-align: middle;
    gap: 8px;
    cursor: pointer;
`;

export const TableRow = styled.tr`
  &:hover {
    background-color: #2a2a2a;
  }
`;



export const SearchBox = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

export const SearchInputWrapper = styled.div`
    display: flex;
    width: 343px;
    height: 48px;
    background: #040610;
    border-radius: 8px;
    border: 1px solid #333;
    color: white;
    align-items: center;
    padding: 0 12px;
`;

export const SearchInput = styled.input`
    width: 100%;
    height: 100%;
    padding: 14px 12px;
    background: transparent;
    border: none;
    color: white;
    font-size: 16px;
    outline: none;
`;

export const SearchIcon = styled(FaSearch)`
    color: white;
    font-size: 18px;
    margin-right: 8px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`;


export const H2 = styled.h2`
    color: white;
    margin: 0;
    font-size: 24px;
    font-weight: 600;
`;
