import React, { useState, useEffect } from "react";
import {
    Button,
    Container,
    InfoCell, InfoCellHead,
    InfoRow,
    InfoTable,
    LeftSection,
    RadioButton,
    RightSection, SectionTitle,
    SelectBox, SubTitle,
    Title
} from "./BusinessApplyDetail.style";
import { useParams } from "react-router-dom";
import { fetchBusinessApplyDetail, updateBusinessState } from "../../service/BusinessApplyService";

const CreatorApplyDetail = () => {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [status, setStatus] = useState("REJECTED");
    const [reason, setReason] = useState(""); // 거절 사유

    // 데이터 로드 함수
    const fetchData = async () => {
        try {
            const result = await fetchBusinessApplyDetail(id);
            setData(result.data);
            setStatus(result.data.state);
            if (result.data?.rejectedReason) {
                setReason(result.data.rejectedReason);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // 확인 버튼 처리
    const handleConfirm = async () => {
        try {
            await updateBusinessState(id, status, reason);
            fetchData();
            alert(`결과가 제출되었습니다.\n상태: ${status}\n사유: ${reason}`);
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };

    if (!data) return <div>Loading...</div>;

    return (
        <Container>
            <LeftSection>
                <SectionTitle>신청자 정보</SectionTitle>
                <InfoTable>
                    <InfoRow>
                        <InfoCellHead>닉네임</InfoCellHead>
                        <InfoCell>{data.user.nickname}</InfoCell>
                    </InfoRow>
                    <InfoRow>
                        <InfoCellHead>연락처</InfoCellHead>
                        <InfoCell>{data.user.phone}</InfoCell>
                    </InfoRow>
                </InfoTable>

                <SectionTitle>신청 정보</SectionTitle>
                <InfoTable>
                    <InfoRow>
                        <InfoCellHead>업체명</InfoCellHead>
                        <InfoCell>{data.businessName}</InfoCell>
                    </InfoRow>
                    <InfoRow>
                        <InfoCellHead>카테고리</InfoCellHead>
                        <InfoCell>{data.category}</InfoCell>
                    </InfoRow>
                    <InfoRow>
                        <InfoCellHead>사업자등록증</InfoCellHead>
                        <InfoCell>사업자등록증사본.jpg</InfoCell>
                    </InfoRow>
                    <InfoRow>
                        <InfoCellHead>사업자 번호</InfoCellHead>
                        <InfoCell>{data.businessNumber}</InfoCell>
                    </InfoRow>
                    <InfoRow>
                        <InfoCellHead>주소</InfoCellHead>
                        <InfoCell>{data.address}</InfoCell>
                    </InfoRow>
                    <InfoRow>
                        <InfoCellHead>상세주소</InfoCellHead>
                        <InfoCell>{data.subAddress}</InfoCell>
                    </InfoRow>
                </InfoTable>
            </LeftSection>
            <RightSection>
                <Title>결과안내</Title>
                <SubTitle>크리에이터 신청결과 안내</SubTitle>
                {data.state === "REGISTERED" && (
                    <label
                        style={{
                            color: "var(--Accent, #D9FF42)",
                            textAlign: "center",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "26px",
                            display: "block",
                            marginTop: "10px",
                        }}
                    >
                        승인
                    </label>
                )}

                {data.state === "REJECTED" && (
                    <label
                        style={{
                            color: "#C62828",
                            textAlign: "center",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "26px",
                            display: "block",
                            marginTop: "10px",
                        }}
                    >
                        거절: {data.rejectedReason}
                    </label>
                )}

                {data.state === "SUBMITTED" && (
                    (
                        <>
                            <RadioButton>
                                <input
                                    type="radio"
                                    name="state"
                                    value="REGISTERED"
                                    checked={status === "REGISTERED"}
                                    onChange={(e) => setStatus(e.target.value)}
                                />
                                <label>승인</label>
                            </RadioButton>
                            <RadioButton>
                                <input
                                    type="radio"
                                    name="state"
                                    value="REJECTED"
                                    checked={status === "REJECTED"}
                                    onChange={(e) => {
                                        setReason("");
                                        setStatus(e.target.value);
                                    }}
                                />
                                <label>거절</label>
                            </RadioButton>
                            {status === "REJECTED" && (
                                <SelectBox value={reason} onChange={(e) => setReason(e.target.value)}>
                                    <option value="">선택하기</option>
                                    <option value="신청자와 대표자 상이">신청자와 대표자 상이</option>
                                    <option value="사업자 등록증 정보 불일치">사업자 등록증 정보 불일치</option>
                                    <option value="사업자 등록증 확인 불가">사업자 등록증 확인 불가</option>
                                </SelectBox>
                            )}


                            <Button onClick={handleConfirm}>확인</Button>
                        </>
                    )
                )}


            </RightSection>
        </Container>
    );
};

export default CreatorApplyDetail;
