import React, { useState, useEffect } from "react";
import {
    Button,
    Container,
    InfoCell, InfoCellHead,
    InfoRow,
    InfoTable,
    LeftSection,
    RadioButton,
    RightSection, SectionTitle,
    SelectBox, SubTitle,
    Title
} from "./CreatorApplyDetail.style";
import {useParams} from "react-router-dom";
import {fetchCreatorApplyDetail, updateStatus} from "../../service/CreatorApplyService";

const STATUS_MESSAGES = {
    REGISTERED: '승인',
    REJECTED: '거절',
};

const CreatorApplyDetail = () => {
    const {id} = useParams();
    const [data, setData] = useState(null);
    const [status, setStatus] = useState("reject");
    const [reason, setReason] = useState(null);

    const fetchData = async () => {
        try {
            const result = await fetchCreatorApplyDetail(id);
            setData(result.data);
            setStatus(result.data.status)
            if(result.data?.rejectedReason) {
                setReason(result.data.rejectedReason)
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    const handleConfirm = async () => {
        try {
            await updateStatus(id, status, reason);
            fetchData()
        } catch (error) {
            console.error("Error fetching creator applications");
        }
        alert(`결과가 제출되었습니다.\n상태: ${STATUS_MESSAGES[status]}${reason ? `\n사유: ${reason}` : ''}`);
    };

    if (!data) return <div>Loading...</div>;

    return (
        <Container>
            <LeftSection>
                <SectionTitle>신청자 정보</SectionTitle>
                <InfoTable>
                    <InfoRow>
                        <InfoCellHead>닉네임</InfoCellHead>
                        <InfoCell>{data.user.nickname}</InfoCell>
                    </InfoRow>
                    <InfoRow>
                        <InfoCellHead>연락처</InfoCellHead>
                        <InfoCell>{data.user.phone}</InfoCell>
                    </InfoRow>
                </InfoTable>

                <SectionTitle>SNS 계정</SectionTitle>
                <InfoTable>
                    {data.sns.map((sns, index) => (
                        <InfoRow key={index}>
                            <InfoCellHead>{sns.type}</InfoCellHead>
                            <InfoCell>
                                <a
                                    href={sns.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        color: "inherit",
                                    }}
                                >
                                    {sns.link}
                                </a>
                            </InfoCell>

                        </InfoRow>
                    ))}
                </InfoTable>
            </LeftSection>
            <RightSection>
                <Title>결과안내</Title>
                <SubTitle>크리에이터 신청결과 안내</SubTitle>
                {data.status === "REGISTERED" && (
                    <label
                        style={{
                            color: "var(--Accent, #D9FF42)",
                            textAlign: "center",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "26px",
                            display: "block",
                            marginTop: "10px",
                        }}
                    >
                        승인
                    </label>
                )}
                {data.status === "REJECTED" && (
                        <label
                        style={{
                        color: "#C62828",
                        textAlign: "center",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "26px",
                        display: "block",
                        marginTop: "10px",
                    }}
                >
                    거절: {data.rejectedReason}
                </label>
                )}

                {data.status ==="SUBMITTED" && (
                    <>
                        <RadioButton>
                            <input
                                type="radio"
                                name="status"
                                value="REGISTERED"
                                checked={status === "REGISTERED"}
                                onChange={(e) => setStatus(e.target.value)}
                            />
                            <label>승인</label>
                        </RadioButton>
                        <RadioButton>
                            <input
                                type="radio"
                                name="status"
                                value="REJECTED"
                                checked={status === "REJECTED"}
                                onChange={(e) => {
                                    setReason(null);
                                    setStatus(e.target.value);
                                }}
                            />
                            <label>거절</label>
                        </RadioButton>
                        {status === "REJECTED" && (
                            <SelectBox value={reason} onChange={(e) => setReason(e.target.value)}>
                                <option value="">선택하기</option>
                                <option value="조건 미달">조건 미달</option>
                                <option value="프로필 비공개">프로필 비공개</option>
                                <option value="본인확인 불가">본인확인 불가</option>
                            </SelectBox>
                        )}
                        <Button onClick={handleConfirm}>확인</Button>
                    </>
                )}

            </RightSection>
        </Container>
    );
};

export default CreatorApplyDetail;